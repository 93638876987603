import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore';
import 'firebase/analytics';

let initializedApp = app.initializeApp({
  apiKey: "AIzaSyBXhLV0ERAkfcVETLNE-n1SXkpHj99DlXU",
  authDomain: "online-vote-31387.firebaseapp.com",
  projectId: "online-vote-31387",
  storageBucket: "online-vote-31387.appspot.com",
  messagingSenderId: "857674346014",
  appId: "1:857674346014:web:83442a2282e5af82df080f",
  measurementId: "G-PHXTZFE4YY"
});


let db = app.firestore(initializedApp);
let auth = app.auth();
const analytics = app.analytics();


export const firebase = {
  app, db, auth, analytics
}