import React, { useEffect, useState, lazy, Suspense } from 'react';
//import { firebase } from './firebase/index';
import './App.css';
//import constants from './constants';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
// import { AuthProvider } from './modules/user/provider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HowToVote from '@material-ui/icons/HowToVote';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Country from 'flagit';
import { firebase } from './firebase/index';
const ParticipateInEvent = lazy(() => import('./modules/participant/screens/participateInEvent'));
const CreateEvent = lazy(() => import('./modules/admin/screens/createEvent'));
const EventUser = lazy(() => import('./modules/participant/screens/event_user'));
const UserProfile = lazy(() => import('./modules/user/profile'));
const MyEvents = lazy(() => import('./modules/user/myevents'));
const NewsBlog = lazy(() => import('./modules/newsBlog/newsBlog'));
const Contact = lazy(() => import('./modules/contact/contact'));

moment.locale('da')

Sentry.init({
  dsn: "https://46cbb69ba71644309be91f909371bc0c@o512135.ingest.sentry.io/5610921",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));


function App({ t }) {

  // firebase.db.collection("Event").doc("j498j49").set({ "a": 3 });
  const classes = useStyles();
  const [userdetails, setuserdetails] = useState(null);
  const [anchorLang, setAnchorLang] = useState(null);
  const [anchorMainMenu, setAnchorMainMenu] = useState(null);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(user => {
      setuserdetails(user);
    })
  })

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorLang(false);
  }

  const openLangMenu = (event) => {
    setAnchorLang(event.currentTarget);
  }

  const openMainMenu = (event) => {
    setAnchorMainMenu(event.currentTarget);
  }

  const closeMainMenu = (event) => {
    setAnchorMainMenu(null);
  }

  const MakeMenu = (props) => {
    if (userdetails) {
      return (
        <>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMainMenu}>
            <HowToVote edge="start" className={classes.icon} />
          </Button>
          <Menu
            id="main menu"
            anchorEl={anchorMainMenu}
            keepMounted
            open={Boolean(anchorMainMenu)}
            onClose={() => setAnchorMainMenu(false)}
          >
            <MenuItem component={Link} to={"/myevents"} onClick={closeMainMenu}>{t('My Events')}</MenuItem>
            <MenuItem component={Link} to={"/user"} onClick={closeMainMenu}>{t('My Participations')}</MenuItem>
            <MenuItem component={Link} to={"/blogNews"} onClick={closeMainMenu}>{t('Blog/News')}</MenuItem>
            <MenuItem component={Link} to={"/contact"} onClick={closeMainMenu}>{t('Contact')}</MenuItem>
            <MenuItem component={Link} to={"/user"} onClick={() => firebase.auth.signOut() && closeMainMenu()}>{t("Logout")}</MenuItem>
          </Menu>
        </>
      )
    } else {
      return (
        <>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMainMenu}>
            <HowToVote edge="start" className={classes.icon} />
          </Button>
          <Menu
            id="main menu"
            anchorEl={anchorMainMenu}
            keepMounted
            open={Boolean(anchorMainMenu)}
            onClose={() => setAnchorMainMenu(false)}
          >
            <MenuItem component={Link} to={"/user"}>{t('Login')}</MenuItem>
            <MenuItem component={Link} to={"/blogNews"}>{t('Blog/News')}</MenuItem>
            <MenuItem component={Link} to={"/contact"}>{t('Contact')}</MenuItem>
          </Menu>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"da"}>
        <Router>
          <AppBar position="relative">
            <Toolbar>
              <MakeMenu />
              <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
                {t('Easy vote')}
              </Typography>
              <Typography component={'span'}>{userdetails && userdetails.displayName}
                <IconButton component={Link} to={"/user"}>
                  {userdetails
                    ? <Avatar alt={userdetails.displayName} src={userdetails.photoURL} />
                    : <PersonIcon edge="end" className={classes.icon} />
                  }
                </IconButton>
              </Typography>
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openLangMenu}>{t('Language')}</Button>
              <Menu
                id="language menu"
                anchorEl={anchorLang}
                keepMounted
                open={Boolean(anchorLang)}
                onClose={() => setAnchorLang(false)}
              >
                <MenuItem onClick={() => changeLanguage('en')}>
                  <Country countryShort="GB" size="sm" />English
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('da')}>
                  <Country countryShort="DK" size="sm" />Dansk
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Switch>

            <Route path="/editEvent/:EventMasterId">
              <Suspense fallback={<div>Loading...</div>}>
                <CreateEvent />
              </Suspense>
            </Route>

            <Route path="/createEvent">
              <Suspense fallback={<div>Loading...</div>}>
                <CreateEvent />
              </Suspense>
            </Route>

            <Route path="/event/:SessionId">
              <Suspense fallback={<div>Loading...</div>}>
                <EventUser />
              </Suspense>
            </Route>

            <Route path="/eventId/:authenticatedUserEventId">
              <Suspense fallback={<div>Loading...</div>}>
                <ParticipateInEvent />
              </Suspense>
            </Route>

            <Route path="/code/:code">
              <Suspense fallback={<div>Loading...</div>}>
                <ParticipateInEvent />
              </Suspense>
            </Route>

            <Route path="/event">
              <Suspense fallback={<div>Loading...</div>}>
                <ParticipateInEvent />
              </Suspense>
            </Route>

            <Route path="/user">
              <Suspense fallback={<div>Loading...</div>}>
                <UserProfile />
              </Suspense>
            </Route>

            <Route path="/myevents">
              <Suspense fallback={<div>Loading...</div>}>
                <MyEvents />
              </Suspense>
            </Route>

            <Route path="/blogNews">
              <Suspense fallback={<div>Loading...</div>}>
                <NewsBlog />
              </Suspense>
            </Route>

            <Route path="/contact">
              <Suspense fallback={<div>Loading...</div>}>
                <Contact />
              </Suspense>
            </Route>


            <Route path="/">

              <div className={classes.heroContent}>
                <Container maxWidth="sm">
                  <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    {t('Easily create')} -
                  </Typography>
                  <Typography variant="h6" align="center" color="textSecondary" paragraph>
                    {t("Easily create - subtitle")}
                    <br />
                    {t("Easily create - description")}
                  </Typography>
                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                      <Grid item>
                        <Button variant="contained" color="primary" component={Link} to={'/createEvent'}>
                          {t("Create")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" component={Link} to={'/event'} >
                          {t("Participate")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Container>
              </div>

            </Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>

    </React.Fragment>
  );
}

export default withNamespaces()(App);
